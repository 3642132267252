<template>
  <div class="incident-list">
    <div class="begin mt-1">
      <div class="header-table flex items-center p-4 bg-white text-lg rounded-xl shadow border cursor-pointer">
        <div class="action w-1/12">
          <checkbox-base :check="isCheck" :id="position" @click.stop="clickOnCheckbox" />
        </div>
        <div class="action w-5/12 mr-4">{{ incidentsLists.typeIncident }}</div>
        <div class="action w-5/12 mr-4">{{ site.name }}</div>
        <div class="action w-5/12 mr-4 flex items-center">
          <div
            v-if="agent.profilePicture !== ''"
            class="picture rounded-full w-12 h-12 mr-2 bg-no-repeat bg-cover"
            v-bind:style="{ backgroundImage: 'url(' + agent.profilePicture + ')' }"
          ></div>
          <div
            v-else
            :style="backgroundDefine"
            class="pictures w-12 h-12 text-center bg-gray-500 pt-4 ml-4 mr-2 rounded-full text-sm font-bold text-white"
          >
            {{ getFirstLetter(agent.name) }}
          </div>
          <div class="name ml-2">{{ agent.name }}</div>
        </div>
        <div class="action w-1/4 mr-4">
          {{ incidentsLists.eventDate ? new Date(incidentsLists.eventDate).toLocaleDateString('FR', options) : new Date(incidentsLists.dateTimeStamp).toLocaleDateString('FR', options) }}
        </div>
        <div class="action w-1/4 text-center">
          <div
            v-if="incidentsLists.status === 'declared'"
            class="status-action"
            :style="{ color: '#4225FC' }"
          >
            {{ $t('declaredIncident') }}
          </div>
          <div
            v-if="incidentsLists.status === 'treated'"
            class="status-action"
            :style="{ color: '#009900' }"
          >
            {{ $t('treatedIncident') }}
          </div>
          <div
            v-if="incidentsLists.status === 'progress'"
            class="status-action"
            :style="{ color: '#FBA705' }"
          >
            {{ $t('goingIncident') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckboxBase from '../add/checkbox-base'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { firestore } from '../../../main'
export default {
  name: 'incident-list',
  components: { CheckboxBase },
  props: {
    incidentsLists: {
      type: Object,
      default: function () {
        return {
        }
      }
    },
    position: {
      type: Number,
      default: 0
    },
    isCheck: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      checking: false,
      options: { year: 'numeric', month: '2-digit', day: '2-digit' },
      site: {},
      agent: {},
      backgroundDefine: {
        backgroundColor: '#000000'
      }
    }
  },
  created () {
    this.getAllSite()
    this.getAgent()
    // console.log(this.incidentsLists)

    const colors = ['#01C8C8', '#FFA83D', '#6D1FEB', '#2F80ED', '#0DCE89', '#7D47B3', '#1ECC78', '#C1CD18', '#581845']
    const value = Math.floor(Math.random() * (colors.length + 1))
    if (value < colors.length) {
      this.backgroundDefine.backgroundColor = colors[value]
    } else {
      this.backgroundDefine.backgroundColor = colors[0]
    }
  },
  methods: {
    getFirstLetter (fullname) {
      const nameTable = fullname.split(' ')
      if (nameTable.length >= 2) {
        return nameTable[0].charAt(0).toUpperCase() + nameTable[1].charAt(0).toUpperCase()
      } else {
        return nameTable[0].charAt(0).toUpperCase() + nameTable[0].charAt(1).toUpperCase()
      }
    },
    async getAllSite () { // GET ALL SITES BY UID
      const q = query(collection(firestore, 'sites'),
        where('uid', '==', this.incidentsLists.site_name))

      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data().identification)
        this.site = doc.data().identification
      })
    },
    async getAgent () { // GET AGENT INFORMATION BY UID
      const q = query(collection(firestore, 'agents'),
        where('uid', '==', this.incidentsLists.addedBy))

      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.agent = doc.data()
      })
    },
    clickOnCheckbox () {
      if (this.isCheck) {
        this.$emit('isJustChecked', this.position)
      } else {
        this.$emit('isJustNotChecked', this.position)
      }
      console.log(this.position)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .picture {
    background-image: url("../../../assets/images/img_user.webp");
  }
  .header-table:hover {
    border-left: 2.5px solid $team_blue;
  }
</style>
