<template>
  <div class="incident-details">
    <popup-base @click="closeDetails">
      <div class="begin bg-white m-auto w-3/4 rounded-lg border">
        <div class="header p-8 flex justify-between">
          <div class="title-bloc">
            <div class="title text-2xl font-medium">{{ $t('incidentDetails') }}</div>
          </div>
          <div class="close cursor-pointer" @click="closeDetails">
            <icon :data="icons.close" original width="25" height="25"/>
          </div>
        </div>
        <div class="body p-8">
          <div class="type flex border-b pb-6">
            <div class="name">{{ $t('incidentType') }} :</div>
            <div class="response ml-4">{{ incidentDetails.typeIncident }}</div>
          </div>
          <div class="type flex border-b py-6">
            <div class="name">{{ $t('sitesMenu') }} :</div>
            <div class="response ml-4 flex items-center">{{ site.name }}</div>
          </div>
          <div v-if="incidentDetails.agentUid" class="type flex items-center border-b py-6">
            <div class="name">{{ $t('supervisors') }} :</div>
            <div class="response ml-4 flex items-center">
              <div
                v-if="supervisor.profilePicture !== ''"
                class="picture rounded-full w-10 h-10 mr-2 bg-no-repeat bg-cover"
                v-bind:style="{ backgroundImage: 'url(' + supervisor.profilePicture + ')' }"
              ></div>
              <div
                v-else
                :style="backgroundDefine"
                class="pictures w-12 h-12 text-center bg-gray-500 pt-4 ml-4 mr-2 rounded-full text-sm font-bold text-white"
              >
                {{ getFirstLetter(supervisor.name) }}
              </div>
              <div class="names ml-2">{{ supervisor.name }}</div>
            </div>
          </div>
          <div class="type flex items-center border-b py-6">
            <div class="name">{{ $t('agentsMenu') }} :</div>
            <div class="response ml-4 flex items-center">
              <div
                v-if="agent.profilePicture !== ''"
                class="picture rounded-full w-10 h-10 mr-2 bg-no-repeat bg-cover"
                v-bind:style="{ backgroundImage: 'url(' + agent.profilePicture + ')' }"
              ></div>
              <div
                v-else
                :style="backgroundDefine"
                class="pictures w-12 h-12 text-center bg-gray-500 pt-4 ml-4 mr-2 rounded-full text-sm font-bold text-white"
              >
                {{ getFirstLetter(agent.name) }}
              </div>
              <div class="names ml-2">{{ agent.name }}</div>
            </div>
          </div>
          <div class="type flex border-b py-6">
            <div class="name">{{ 'Date' }} :</div>
            <div class="response ml-4">
              {{ incidentDetails.eventDate ? new Date(incidentDetails.eventDate).toLocaleDateString('FR', options) : new Date(incidentDetails.dateTimeStamp).toLocaleDateString('FR', options) }}
            </div>
          </div>
          <div class="type flex border-b py-6">
            <div class="name">{{ $t('description') }} :</div>
            <div class="response ml-4">{{ incidentDetails.event_description }}</div>
          </div>
          <div class="type py-6">
            <div class="name">{{ $t('joinPieces') }} :</div>
            <div class="response flex items-center">
              <div v-if="Array.isArray(incidentDetails.report)" class="flex items-center">
                <div
                  v-for="(item, index) in incidentDetails.report"
                  :key="index"
                  class="picture-piece rounded-lg w-20 h-20 bg-cover bg-no-repeat mr-4 cursor-pointer"
                  v-bind:style="{ backgroundImage: 'url(' + item + ')' }"
                  @click="openFile(index)"
                ></div>
              </div>
              <div
                v-else
                class="picture-piece rounded-lg w-20 h-20 bg-cover bg-no-repeat mr-4 cursor-pointer"
                v-bind:style="{ backgroundImage: 'url(' + incidentDetails.report + ')' }"
                @click="openFile"
              ></div>
            </div>
          </div>
        </div>
        <div v-if="incidentDetails.status !== 'treated'" class="footer">
          <div class="header p-8 text-xl flex items-center justify-between">
            <div class="pro font-medium">{{ $t('inProgressTreatment') }}</div>
            <div>
              <Toggle
                v-model="value"
                :classes="{
                  container: 'inline-block rounded-full outline-none focus:ring-transparent',
                  handle: 'inline-block bg-white w-5 h-5 top-0 rounded-full absolute transition-all'
                }"
                @change="changeStatus"
              />
            </div>
          </div>
          <div class="treat-button p-8">
            <button-base
              :label="$t('treatIncidentButton')"
              @click="isCloseDetails"
            />
          </div>
        </div>
      </div>
    </popup-base>
  </div>
</template>

<script>
import PopupBase from '../add/popup-base'
import ButtonBase from '../add/button-base'
import Toggle from '@vueform/toggle'
import { collection, getDocs, query, where, doc, updateDoc } from 'firebase/firestore'
import { firestore } from '../../../main'
import close from '../../../assets/icons/ic_close.svg'
export default {
  name: 'incident-details',
  components: { Toggle, ButtonBase, PopupBase },
  props: {
    incidentDetails: Object
  },
  data () {
    return {
      icons: { close },
      value: false,
      agent: {},
      supervisor: {},
      site: {},
      options: { year: 'numeric', month: '2-digit', day: '2-digit' },
      backgroundDefine: {
        backgroundColor: '#000000'
      }
    }
  },
  created () {
    this.getAgent()
    this.getAllSite()
    this.checkIncidentStatus()
    this.getSupervisor()
    // console.log(this.incidentDetails)

    const colors = ['#01C8C8', '#FFA83D', '#6D1FEB', '#2F80ED', '#0DCE89', '#7D47B3', '#1ECC78', '#C1CD18', '#581845']
    const value = Math.floor(Math.random() * (colors.length + 1))
    if (value < colors.length) {
      this.backgroundDefine.backgroundColor = colors[value]
    } else {
      this.backgroundDefine.backgroundColor = colors[0]
    }
  },
  methods: {
    openFile (index) {
      if (Array.isArray(this.incidentDetails.report)) {
        window.open(this.incidentDetails.report[index], '_blank')
      } else {
        window.open(this.incidentDetails.report, '_blank')
      }
    },
    getFirstLetter (fullname) {
      const nameTable = fullname.split(' ')
      if (nameTable.length >= 2) {
        return nameTable[0].charAt(0).toUpperCase() + nameTable[1].charAt(0).toUpperCase()
      } else {
        return nameTable[0].charAt(0).toUpperCase() + nameTable[0].charAt(1).toUpperCase()
      }
    },
    async changeStatus () { // UPDATE THE INCIDENT STATUS
      // console.log(this.value)
      if (this.value === true) {
        const incidentRef = doc(firestore, 'courant_hands', this.incidentDetails.uid)
        await updateDoc(incidentRef, {
          status: 'progress',
          updatedAt: new Date().getTime(),
          updateBy: this.$store.getters.userId
        }).then(response => {
          console.log('status updated successfully ' + response)
          this.closeDetails()
        })
      } else {
        const incidentRef = doc(firestore, 'courant_hands', this.incidentDetails.uid)
        await updateDoc(incidentRef, {
          status: 'declared',
          updatedAt: new Date().getTime(),
          updateBy: this.$store.getters.userId
        }).then(response => {
          console.log('status updated successfully ' + response)
          this.closeDetails()
        })
      }
    },
    checkIncidentStatus () { // CHECK IF THE INCIDENT STATUS IS IN PROGRESS OR NOT
      if (this.incidentDetails.status === 'declared') {
        this.value = false
      } else if (this.incidentDetails.status === 'progress') {
        this.value = true
      }
    },
    async getAllSite () { // GET SITE DETAILS
      const q = query(collection(firestore, 'sites'),
        where('uid', '==', this.incidentDetails.site_name))

      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data().identification)
        this.site = doc.data().identification
      })
    },
    async getAgent () { // GET AGENT DETAILS
      let agent = ''
      if (this.incidentDetails.agentUid) {
        agent = this.incidentDetails.agentUid
      } else {
        agent = this.incidentDetails.addedBy
      }
      const q = query(collection(firestore, 'agents'),
        where('uid', '==', agent))

      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.agent = doc.data()
      })
    },
    async getSupervisor () { // GET AGENT DETAILS
      const q = query(collection(firestore, 'agents'),
        where('uid', '==', this.incidentDetails.addedBy))

      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.supervisor = doc.data()
      })
    },
    isCloseDetails () {
      this.$emit('closingDetails', false)
    },
    closeDetails () {
      this.$emit('closeIncidentDetails', false)
    }
  }
}
</script>

<style src="@vueform/toggle/themes/default.css"></style>
<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .begin {
    border-top: 4px solid $team_blue;
  }
  .header {
    background: $team_gray_popup;
    color: $team_blue;
  }
  .name {
    color: #909090;
  }
  .picture {
    background-image: url("../../../assets/images/img_user.webp");
  }
  .picture-piece {
    background-image: url("../../../assets/images/img_food.webp");
  }
  .treat-button::v-deep {
    .button-base {
      text-align: right;
      text-align: -moz-right;
      text-align: -webkit-right;
      .page-button-real {
        border-radius: .25rem;
        width: 50%;
        h4 {
          font-size: 1.25rem;
        }
      }
    }
  }
</style>
