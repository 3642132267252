<template>
  <div class="incidents">
    <div class="begin pb-12">
      <div class="header">
        <the-header :show-add-button="false" :show-file-button="false" :show-number="false" @searchForItems="searchForItems"/>
      </div>
      <div class="body">
        <div class="header-table flex items-center p-4 bg-white rounded-xl border font-medium text-lg">
          <div class="action w-1/12">
            <checkbox-base :check="allCheck" @click="allCheckChange"/>
          </div>
          <div class="action w-5/12 mr-4">{{ $t('incidentType') }}</div>
          <div class="action w-5/12 mr-4">{{ $t('sitesMenu') }}</div>
          <div class="action w-1/3 mr-4">{{ $t('agentsMenu') + '/' + $t('supervisors') }}</div>
          <div class="action w-1/4 mr-4">{{ truncateString($t('becomeDate'), 13) }}</div>
          <div class="action w-1/4 text-center">{{ $t('status') }}</div>
        </div>
        <div class="header-body">
          <div class="loading" :class="{ 'not_empty': loading === false }">
            <loading
              :active="loading"
              :can-cancel="false"
              :on-cancel="onCancel"
              :is-full-page="fullPage"
              background-color="#FFFFFF"
              color="#324971"
              loader="dots"
            ></loading>
          </div>
          <div
            v-if="nothing && !loading"
            class="no-student text-center text-xl text-gray-500 py-32"
          >
            {{ $t('noIncidentDeclared') }}
          </div>
          <div v-if="!loading">
            <incident-list
              v-for="(item, index) in sortedArrayIncident"
              :key="index"
              :incidents-lists="item"
              :is-check="isAllChecked"
              @isJustChecked="isJustChecked"
              @isJustNotChecked="isJustNotChecked"
              @click="openDetails(index)"
            />
          </div>
        </div>
      </div>
    </div>
    <incident-details
      v-if="isIncidentDetails"
      :incident-details="incidentDetail"
      @closeIncidentDetails="closeIncidentDetails"
      @closingDetails="closingDetails"
    />
    <incident-treatment
      v-if="isIncidentTreatment"
      :treated-incident-details="incidentDetail"
      @openIncidentSuccess="openIncidentSuccess"
      @closeIncidentTreatment="closeIncidentTreatment"
    />
    <incident-success
      v-if="isIncidentSuccess"
      :incident-info="incidentDetail"
      @closeSuccess="closeSuccess"
    />
  </div>
</template>

<script>
import TheHeader from '../../components/the-header'
import CheckboxBase from '../../components/helper/add/checkbox-base'
import IncidentList from '../../components/helper/incidents/incident-list'
import IncidentDetails from '../../components/helper/incidents/incident-details'
import IncidentTreatment from '../../components/helper/incidents/incident-treatment'
import IncidentSuccess from '../../components/helper/incidents/incident-success'
import Loading from 'vue3-loading-overlay'
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css'
import { collection, orderBy, query, where, onSnapshot } from 'firebase/firestore'
import { firestore } from '../../main'
export default {
  name: 'index',
  components: { Loading, IncidentSuccess, IncidentTreatment, IncidentDetails, IncidentList, CheckboxBase, TheHeader },
  data () {
    return {
      allIncidents: [],
      isAllChecked: true,
      selectedInMatch: [],
      isNotSelected: [],
      allCheck: true,
      isIncidentDetails: false,
      isIncidentTreatment: false,
      isIncidentSuccess: false,
      loading: true,
      fullPage: false,
      nothing: false,
      incidentDetail: {},
      searching: ''
    }
  },
  created () {
    this.getAllIncidents()
  },
  computed: {
    sortedArrayIncident: function () {
      let sorted = this.allIncidents
      if (this.searching !== '') {
        let authorNameSearchString = this.searching
        authorNameSearchString = authorNameSearchString.toLowerCase()
        sorted = sorted.filter(function (item) {
          if ((item.typeIncident !== null && (item.typeIncident.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1)) {
            // console.log(item)
            return item
          }
        })
      }
      return sorted
    }
  },
  methods: {
    async getAllIncidents () { // GET ALL INCIDENT
      const q = query(collection(firestore, 'courant_hands'),
        where('event', '==', 'Phi7eAYqBkEFolaATaxW'),
        orderBy('createdAt', 'desc'))
      onSnapshot(q, (querySnapshot) => {
        const cities = []
        querySnapshot.forEach((doc) => {
          // console.log(doc.data())
          cities.push(doc.data())
          this.allIncidents = cities
        })
        if (this.allIncidents.length === 0) {
          this.loading = false
          this.nothing = true
        } else {
          this.loading = false
          this.nothing = false
        }
      })
    },
    searchForItems (val) { // GET INPUT SEARCH
      this.searching = val
    },
    async closeSuccess (val) {
      this.isIncidentSuccess = val
      /* this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 1000) */
    },
    closeIncidentTreatment (val) {
      this.isIncidentTreatment = val
      this.isIncidentDetails = true
    },
    openIncidentSuccess (val) {
      this.isIncidentTreatment = val
      this.isIncidentSuccess = true
    },
    closingDetails (val) {
      this.isIncidentDetails = val
      this.isIncidentTreatment = true
    },
    closeIncidentDetails (val) {
      this.isIncidentDetails = val
      /* this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 1000) */
    },
    openDetails (index) {
      this.isIncidentDetails = true
      this.incidentDetail = this.sortedArrayIncident[index]
    },
    allCheckChange () {
      this.isAllChecked = this.allCheck
    },
    truncateString (str, num) {
      if (str.length <= num) {
        return str
      }
      return str.slice(0, num) + '...'
    },
    isJustChecked (answer) {
      this.allCheck = false
      this.selectedInMatch.push(answer)
    },
    isJustNotChecked (answer) {
      for (let i = 0; i < this.selectedInMatch.length; i++) {
        if (this.selectedInMatch[i] === answer) {
          this.selectedInMatch.splice(i, 1)
        }
      }
      this.allCheck = false
      this.isNotSelected.push(answer)
    },
    onCancel () {
      console.log('User cancelled the loader.')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .action {
    color: $team_blue;
  }
  .loading {
    position: relative;
    height: 10vh;
  }
  .not_empty {
    height: auto;
  }
</style>
